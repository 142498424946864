import React from "react";
import withCustomStyles from "./BreadcrumbsListPart.style";
import { withRouter } from "react-router";
import gtmService from "../services/GTMService";
import Link from "@material-ui/core/Link";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link as RouterLink } from "react-router-dom";
import {
  getProductsPageUrl,
  getSiteMapProductsPageUrl
} from "../utils/UrlUtils";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";

const BreadcrumbsListPart = props => {
  const {
    categories,
    classes,
    isSiteMap,
    location: { pathname }
  } = props;

  const handleCategoryClick = () => {
    gtmService.event("Navigation", "breadcrumbs used", pathname);
  };

  const getUrl = category => {
    return isSiteMap
      ? getSiteMapProductsPageUrl(category)
      : getProductsPageUrl(category);
  };

  return (
    <Breadcrumbs separator="›" arial-label="Breadcrumb">
      <Link className={classes.breadcrumbText} component={RouterLink} to="/">
        Home
      </Link>
      {categories.map(category =>
        getUrl(category) !== pathname ? (
          <Link
            color="inherit"
            key={category.id}
            component={RouterLink}
            to={getUrl(category)}
            className={classnames(classes.link, classes.breadcrumbText)}
            onClick={handleCategoryClick}
            TypographyClasses={classes.breadcrumbText}
          >
            {category.name}
          </Link>
        ) : (
          <Typography className={classes.breadcrumbText} key={category.id} >
            {category.name}
          </Typography>
        )
      )}
      {props.productName && (
        <Link color="inherit" className={classes.nonClickable}>
          {props.productName}
        </Link>
      )}
    </Breadcrumbs>
  );
};

export default withRouter(withCustomStyles(BreadcrumbsListPart));
