import React from "react";
import withCustomStyles from "./SearchPart.style";
import Grid from "@material-ui/core/Grid";
// import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { inject, observer } from "mobx-react";
// import _debounce from "lodash/debounce";
import { withRouter } from "react-router";
import { getSearchPageUrl } from "../utils/UrlUtils";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import algoliasearch from "algoliasearch/lite";
import { autocomplete } from "@algolia/autocomplete-js";
import "@algolia/autocomplete-theme-classic";

@inject(["searchProductsStore"])
@observer
class SearchPart extends React.Component {
  state = {
    query: ""
  };
  searchRef = React.createRef();

  // search = _debounce(query => {
  //   this.setState({
  //     ...this.state,
  //     query
  //   });
  //   if (query) {
  //     this.props.history.push(getSearchPageUrl(query));
  //   }
  // }, 400);
  searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP,
    process.env.REACT_APP_ALGOLIA_APP_SECRET
  );

  componentDidMount() {
    if (!this.searchRef.current) return;

    const index = this.searchClient.initIndex(
      process.env.REACT_APP_ALGOLIA_INDEX
    );

    autocomplete({
      container: this.searchRef.current,
      openOnFocus: false,
      insights: true,
      placeholder: "Search",
      getSources: ({ query, setQuery }) => [
        {
          sourceId: "products",
          getItems: () =>
            index.search(query, { hitsPerPage: 6 }).then(({ hits }) => hits),
          templates: {
            item: ({ item }) => `${item.name}`
          },
          onSelect: ({ item }) => {
            this.setState({ query: item.name });
            setQuery(item.name);

            const input = this.searchRef.current.querySelector("input");
            if (input) {
              input.value = item.name;
            }
          }
        }
      ],
      onStateChange: ({ state }) => {
        this.setState({ query: state.query });
      }
    });
    const button = document.querySelector(".aa-InputWrapperPrefix");
    const input = document.querySelector(".aa-Input");
    if (button) {
      button.style.display = "none";
      input.style.padding = "10px";
    }
  }

  doSearch = () => {
    if (this.state.query) {
      this.props.history.push(getSearchPageUrl(this.state.query));
    }
  };

  // componentWillReceiveProps(nextProps) {
  //   if (
  //     nextProps.location.pathname !== "/search" &&
  //     this.state.query &&
  //     this.searchRef.current
  //   ) {
  //     this.search("");
  //     this.searchRef.current.value = "";
  //   }
  // }

  render() {
    const {
      props: { classes },
      state: { query }
    } = this;

    return (
      <div className={classes.search}>
        <Grid
          direction="row"
          justify="space-between"
          alignItems="stretch"
          container
        >
          <Grid item className={classes.inputItem} xs>
            <div
              ref={this.searchRef}
              role="textbox"
              tabIndex="0"
              onKeyDown={e => {
                if (e.key === "Enter") {
                  this.doSearch();
                }
              }}
            ></div>
          </Grid>
          <Grid item className={classes.iconItem}>
            <Link
              component={RouterLink}
              to={getSearchPageUrl(query)}
              color={"inherit"}
              aria-label="Search button"
              className={classes.searchIcon}
            >
              <SearchIcon />
            </Link>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withCustomStyles(SearchPart));
